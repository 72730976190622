import "./styles.css";
import React from "react";
import InsaneGoogleMap from "../../component/insane-google-map";
import { HttpRequest } from "../../component/service/HttpRequest";
import Spinner from "../../component/spinner";
import ic_distress from "../../assets/icons/distress-marker.png";
import ic_falling from "../../assets/icons/falling-marker.png";
import ic_user from "../../assets/icons/user-marker.png";
import pinDrop from "../../assets/images/pin_drop_FILL0_red.svg";

function App() {
  const [isLoaded, setIsLoaded] = React.useState(false);

  const [modeDisplay, setModeDisplay] = React.useState("map"); // map, table

  const [MARKERS, setMARKERS] = React.useState([]);
  const [POLYLINES, setPOLYLINES] = React.useState([]);

  const [DistanceMap, setDistanceMap] = React.useState(5);
  const [ZoomMap, setZoomMap] = React.useState(11);
  const [CenterMap, setCenterMap] = React.useState({
    lat: 13.84283,
    lng: 100.67356,
  });

  const [CurrentCenterMap, setCurrentCenterMap] = React.useState({
    lat: 13.84283,
    lng: 100.67356,
  });

  const [StampCenterMap, setStampCenterMap] = React.useState({
    lat: 13.84283,
    lng: 100.67356,
  });

  const [DATA_ROAD_DISTRESS, SET_DATA_ROAD_DISTRESS] = React.useState([]);
  // const [DATA_ROAD_NET, SET_DATA_ROAD_NET] = React.useState([]);
  const [DATA_ROAD_STATUS, SET_DATA_ROAD_STATUS] = React.useState([]);
  const [DATA_FALLING, SET_DATA_FALLING] = React.useState([]);
  const [DATA_USER, SET_DATA_USER] = React.useState([]);

  const [TOGGLE_LAYER, SET_TOGGLE_LAYER] = React.useState({
    ROAD_DISTRESS: { Label: "Road Distress", state: true },
    // ROAD_NET: { Label: "Road Net", state: true },
    ROAD_STATUS: { Label: "Road Status", state: true },
    FALLING: { Label: "Falling", state: true },
    USER: { Label: "User", state: true },
  });

  const [BoundsMap, setBoundsMAP] = React.useState(null);

  const onChangeBounds = React.useCallback(
    (boundMap) => {
      setBoundsMAP(boundMap);
    },
    [BoundsMap]
  );

  const [timerRefresh, setTimerRefresh] = React.useState(0);

  function formatObjectMarker(ArrMarker, icon, label) {
    let result = [];
    for (let i = 0; i < ArrMarker.length; i++) {
      const marker = ArrMarker[i];

      if (marker.latitude && marker.longitude) {
        result.push({
          label: label,
          name: marker.cs_no,
          position: {
            lat: marker.latitude,
            lng: marker.longitude,
          },
          icon: icon,
          row_data: marker,
        });
      }
    }
    return result;
  }

  function formatObjectPolyline(ArrPolyline, type) {
    let result = [];
    for (let i = 0; i < ArrPolyline.length; i++) {
      const polyline = ArrPolyline[i];
      let color_line = "#7B7D7D";
      if (type == "status") {
        color_line = colorRoadStatus(polyline.iri);
      }

      let new_polyline = {
        label: type,
        name: polyline.road_no,
        color: color_line,
        row_data: polyline,
        position: {
          lat: polyline.geo.coordinates[0][1],
          lng: polyline.geo.coordinates[0][0],
        },
        path: [],
      };

      for (let j = 0; j < polyline.geo.coordinates.length; j++) {
        const geo_position = polyline.geo.coordinates[j];
        new_polyline.path.push({
          lat: geo_position[1],
          lng: geo_position[0],
        });
      }

      result.push(new_polyline);
    }
    return result;
  }

  function colorRoadStatus(iri) {
    // console.log(iri)
    // let iri = _iri * 2;
    if (iri > 9) return "#43025c";
    if (iri > 8) return "#5b047e";
    if (iri > 7) return "#7809a3";
    if (iri > 6) return "#8e17bd";
    if (iri > 5) return "#aa25c8";
    if (iri > 4) return "#9c3ab2";
    if (iri > 3) return "#d164e9";
    if (iri > 2) return "#e583fb";
    if (iri > 1) return "#f1bcfd";
    return "#f1bcfd";
  }

  Array.prototype.unique = function () {
    var a = this.concat();
    for (var i = 0; i < a.length; ++i) {
      for (var j = i + 1; j < a.length; ++j) {
        if (a[i] === a[j]) a.splice(j--, 1);
      }
    }

    return a;
  };

  async function onRequestServer() {
    setTimerRefresh(0);

    await HttpRequest("/admin/getuserlist", {
      latitude: 13.84283,
      longitude: 100.67356,
      distancekm: 2000,
      index: null,
      count: null,
    }).then((data) => {
      // console.log("HttpRequest getuserlist :", data);
      if (data.result === "OK") {
        let user = data.userlist;
        SET_DATA_USER(user);
      }
    });

    await HttpRequest("/admin/getfallingdetectionlist", {
      latitude: 13.84283,
      longitude: 100.67356,
      distancekm: 2000,
      starttime: null,
      stoptime: null,
      index: null,
      count: null,
    }).then((data) => {
      // console.log("HttpRequest getfallingdetectionlist :", data);
      if (data.result === "OK") {
        let falling = data.getfallingdetectionlist;
        SET_DATA_FALLING(falling);
      }
    });

    await HttpRequest("/admin/getroaddata", {
      latitude: CurrentCenterMap.lat,
      longitude: CurrentCenterMap.lng,
      distancekm: DistanceMap,
    }).then((data) => {
      console.log("HttpRequest getroaddata :", data);
      if (data.result === "OK") {
        let road_distress = data.roaddata.roaddistress.rows;
        let road_status = data.roaddata.roadstatus.rows;
        // let road_net = data.roaddata.roadnet.rows;

        SET_DATA_ROAD_DISTRESS(road_distress);
        SET_DATA_ROAD_STATUS(road_status);
        // SET_DATA_ROAD_NET(road_net);
      }
    });

    setIsLoaded(true);
  }

  async function onRequestServerInterval() {
    setTimerRefresh(0);

    await HttpRequest("/admin/getuserlist", {
      latitude: 13.84283,
      longitude: 100.67356,
      distancekm: 2000,
      index: null,
      count: null,
    }).then((data) => {
      // console.log("HttpRequest getuserlist :", data);
      if (data.result === "OK") {
        let user = data.userlist;
        SET_DATA_USER(user);
      }
    });

    await HttpRequest("/admin/getfallingdetectionlist", {
      latitude: 13.84283,
      longitude: 100.67356,
      distancekm: 2000,
      starttime: null,
      stoptime: null,
      index: null,
      count: null,
    }).then((data) => {
      // console.log("HttpRequest getfallingdetectionlist :", data);
      if (data.result === "OK") {
        let falling = data.getfallingdetectionlist;
        SET_DATA_FALLING(falling);
      }
    });
  }

  async function onToggleDisplayLayerMap() {
    setMARKERS([]);
    setPOLYLINES([]);

    if (TOGGLE_LAYER.ROAD_DISTRESS.state === true) {
      let road_distress_markers = formatObjectMarker(
        DATA_ROAD_DISTRESS,
        ic_distress,
        "road_distress"
      );
      setMARKERS((oldState) => [...oldState, ...road_distress_markers]);
    }

    if (TOGGLE_LAYER.ROAD_STATUS.state === true) {
      let road_net_status = formatObjectPolyline(DATA_ROAD_STATUS, "status");
      setPOLYLINES((oldState) => [...oldState, ...road_net_status]);
    }

    if (TOGGLE_LAYER.FALLING.state === true) {
      let falling_markers = formatObjectMarker(
        DATA_FALLING,
        ic_falling,
        "falling"
      );
      setMARKERS((oldState) => [...oldState, ...falling_markers]);
    }

    if (TOGGLE_LAYER.USER.state === true) {
      let user_markers = formatObjectMarker(DATA_USER, ic_user, "user");
      setMARKERS((oldState) => [...oldState, ...user_markers]);
    }
  }

  var rad = function (x) {
    return (x * Math.PI) / 180;
  };

  var getDistance = function (p1, p2) {
    var R = 6378137; // Earth’s mean radius in meter
    var dLat = rad(p2.lat - p1.lat);
    var dLong = rad(p2.lng - p1.lng);
    var a =
      Math.sin(dLat / 2) * Math.sin(dLat / 2) +
      Math.cos(rad(p1.lat)) *
        Math.cos(rad(p2.lat)) *
        Math.sin(dLong / 2) *
        Math.sin(dLong / 2);
    var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    var d = R * c;
    return d; // returns the distance in meter
  };

  const intervalRef = React.useRef();

  React.useEffect(() => {
    const controller = new AbortController();

    onRequestServer();

    return () => {
      controller.abort();
    };
  }, []);

  React.useEffect(() => {
    const controller = new AbortController();

    // console.log("timerRefresh : ", timerRefresh);

    if (timerRefresh == 60) {
      onRequestServerInterval();
    }

    return () => {
      controller.abort();
    };
  }, [timerRefresh]);

  React.useEffect(() => {
    const RefreshInternal = setInterval(() => {
      setTimerRefresh((t) => t + 1);
    }, 1000);
    return () => {
      clearInterval(RefreshInternal);
    };
  }, []);

  React.useEffect(() => {
    const controller = new AbortController();

    // console.log("Markers : ", MARKERS);

    return () => {
      controller.abort();
    };
  }, [MARKERS]);

  React.useEffect(() => {
    const controller = new AbortController();

    if (BoundsMap) {
      let center = BoundsMap.getCenter();
      // console.log("getCenter : ", center.lat(), center.lng());
      setCurrentCenterMap({
        lat: center.lat(),
        lng: center.lng(),
      });

      // console.log("CurrentCenterMap --> ", CurrentCenterMap);
      // console.log("StampCenterMap --> ", StampCenterMap);

      let distance = getDistance(CurrentCenterMap, StampCenterMap);

      // console.log("distance --> ", distance);

      if (distance > 5000) {
        setStampCenterMap({
          lat: center.lat(),
          lng: center.lng(),
        });
        onRequestServer();
      }
    }

    return () => {
      controller.abort();
    };
  }, [BoundsMap]);

  React.useEffect(() => {
    const controller = new AbortController();

    onToggleDisplayLayerMap();

    return () => {
      controller.abort();
    };
  }, [TOGGLE_LAYER, timerRefresh === 5]);

  return (
    <div className="main-content">
      <div className="realtime-container-title">
        {modeDisplay === "map" ? (
          <span style={{ flexGrow: 1 }}>แผนที่แสดงภาพผิวทาง</span>
        ) : (
          <span style={{ flexGrow: 1 }}>ตารางจัดการอุบัติเหตุ</span>
        )}

        {timerRefresh < 6 ? (
          <div className="timer-spiner">
            <Spinner></Spinner>
          </div>
        ) : null}

        <button
          className="form-control"
          style={{ marginRight: 20, marginLeft: 20, width: "unset" }}
        >
          <img style={{ width: 25, marginRight: 10 }} src={pinDrop} />
          {"[ "}
          {CurrentCenterMap.lat}, {CurrentCenterMap.lng}
          {" ]"} ระยะ {DistanceMap} กิโลเมตร
        </button>

        <button className="form-control" onClick={() => onRequestServer()}>
          Update Map
        </button>
      </div>

      <div className="content-google-map">
        <div className="content">
          {isLoaded ? (
            <div className="container-map">
              <div className="toggle-layer">
                {Object.keys(TOGGLE_LAYER).map((layer, index) => (
                  <div
                    key={index}
                    className={
                      "toggle " +
                      layer +
                      (TOGGLE_LAYER[layer].state ? " active" : null)
                    }
                    onClick={() => {
                      let update_layer = { ...TOGGLE_LAYER };
                      update_layer[layer].state === true
                        ? (update_layer[layer].state = false)
                        : (update_layer[layer].state = true);
                      SET_TOGGLE_LAYER(update_layer);
                    }}
                  >
                    {TOGGLE_LAYER[layer].Label}
                  </div>
                ))}
              </div>
              <InsaneGoogleMap
                center={CenterMap}
                zoom={ZoomMap}
                distance={DistanceMap}
                markers={MARKERS}
                polylines={POLYLINES}
                polygons={[]}
                callBack={onChangeBounds}
              ></InsaneGoogleMap>
            </div>
          ) : (
            <>
              <Spinner></Spinner>
              <div>กำลังโหลด</div>
            </>
          )}
        </div>
      </div>
    </div>
  );
}

export default App;

import "./styles.css";
import React from "react";

import { HttpRequest } from "../../component/service/HttpRequest";

import InsaneTableNoHttp from "../../component/insane-table-no-http";
import Spinner from "../../component/spinner";

function App() {

  const [dataTable, setDataTable] = React.useState([]);
  const [limitRow, setLimitRow] = React.useState(10);
  const [loading, setLoading] = React.useState(true);

  const tableOption = {
    tableName: "Road Detail Table",
    className: "",
    mapTable: [
      {
        displayName: "User ID",
        attName: "userid",
        type: "text",
        form: {
          validate: true,
          element: "input",
        },
      },
      {
        displayName: "Mobile no",
        attName: "mobileno",
        type: "text",
        form: {
          validate: true,
          element: "input",
        },
      },
      {
        displayName: "Acc Distance km",
        attName: "acc_distance_km",
        type: "text",
        form: {
          validate: true,
          element: "input",
        },
      },
      {
        displayName: "Active Time",
        attName: "activetime",
        type: "date",
        form: {
          validate: true,
          element: "input",
        },
      },
    ],
    action: [
      // {
      //   name: "แก้ไข",
      //   mode: "page", /// page, modal, form
      //   option: {
      //     url: "",
      //   },
      // },
    ],
  };

  // async function onRequest(state = "") {
  //   setDataTable([]);
  // }

  React.useEffect(() => {
    const controller = new AbortController();
    setLoading(true);

    HttpRequest("/admin/getuserlist", {
      latitude: 13.84283,
      longitude: 100.67356,
      distancekm: 2000,
      index: 0,
      count: limitRow,
    }).then((data) => {
      console.log("HttpRequest getuserlist :", data);
      if (data.result === "OK") {
        setDataTable(data.userlist);
      }

      setLoading(false);
    });

    return () => {
      controller.abort();
    };
  }, []);

  return (
    <>
      {loading ? (
        <div
          className="container-content-dashboad"
          style={{ alignItems: "center", justifyContent: "center" }}
        >
          <Spinner></Spinner>
          <div>กำลังโหลด</div>
        </div>
      ) : (
        <div className="main-content">
          <div className="table-container">
            <div className="table-container-title">ตารางจัดการผู้ใช้งาน</div>
            <div className="table-container-limit-row">
              <span>แสดง</span>
              <input
                className="form-control"
                type="number"
                style={{ marginRight: 20 }}
                value={limitRow}
                onChange={(e) => setLimitRow(e.target.value)}
              ></input>
              <span>รายการ</span>
            </div>
            <div className="table-container-content">
              <InsaneTableNoHttp
                className=""
                option={tableOption}
                dataTable={dataTable}
                limitRow={limitRow}
              ></InsaneTableNoHttp>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default App;

//////////// CSS /////////////
import "./styles.css";
// import ic_search from './assets/icon/ic_search_black.png';
import ic_true from "./assets/icon/true.png";
import ic_false from "./assets/icon/false.png";

//////////// REACT /////////////
import React from "react";
import { useNavigate } from "react-router-dom";
// import QRCode from "react-qr-code";

//////////// REDUX | STORE | SERVICE /////////////
// import { useDispatch } from 'react-redux';
// import { showLoad, dismissLoad } from '../../store/slice/loading';

//////////// COMPONENT /////////////
import Modal from "./component/action-modal-form";
import ModalAction from "./component/action-modal-form-action";
import ModalViewImage from "./component/modal-view-image";

function InsaneTableNoHttp(props) {
  // console.log(props);

  const navigate = useNavigate();

  const tableOption = props.option;
  const dataTable = props.dataTable;

  const limitRow = props.dataTable.length;
  const [dataTableMissRow, setDataTableSplitMissRow] = React.useState(0);

  const [headTable, setHeadTable] = React.useState(tableOption.mapTable);

  React.useEffect(() => {
    // console.log("on props change:", props);
    const controller = new AbortController();

    onActiveDataTable();

    return () => {
      controller.abort();
    };
  }, [props]); // eslint-disable-line react-hooks/exhaustive-deps

  function onActiveDataTable() {
    setDataTableSplitMissRow(0);

    let countMissRow = limitRow - dataTable.length;
    setDataTableSplitMissRow(countMissRow);
  }

  function twoDigit(n) {
    let number = parseInt(n);
    if (number <= 9) {
      return "0" + number;
    } else {
      return "" + number;
    }
  }

  function ConvertDate(dateString, type = "datetime") {
    if (dateString) {
      let date = new Date(dateString);
      if (type === "datetime") {
        return (
          date.getFullYear() +
          "-" +
          twoDigit(date.getMonth() + 1) +
          "-" +
          twoDigit(date.getDate()) +
          " " +
          twoDigit(date.getHours()) +
          ":" +
          twoDigit(date.getMinutes()) +
          ":" +
          twoDigit(date.getSeconds())
        );
      }
      if (type === "date") {
        return (
          date.getFullYear() +
          "-" +
          twoDigit(date.getMonth() + 1) +
          "-" +
          twoDigit(date.getDate())
        );
      }
      if (type === "time") {
        return (
          twoDigit(date.getHours()) +
          ":" +
          twoDigit(date.getMinutes()) +
          ":" +
          twoDigit(date.getSeconds())
        );
      }
    } else {
      return "";
    }
  }

  function onPageNavigate(url, data) {
    navigate(url);
  }

  function getValueById(attCompare, arrForFilter, valueFilter) {
    // console.log(attCompare, arrForFilter, valueFilter);
    let _find = arrForFilter.filter((e) => {
      return e[attCompare] === valueFilter;
    });
    if (_find.length > 0) {
      return _find[0].displayName;
    } else {
      return "";
    }
  }

  const onSort = (sortby, index) => {
    let _headTable = [...headTable];
    let sortasc = "ASC";
    console.log(sortby, index);

    if (_headTable[index].sortasc && _headTable[index].sortasc === "DESC") {
      for (let i = 0; i < _headTable.length; i++) {
        _headTable[i].sortasc = "";
      }
      _headTable[index] = { ..._headTable[index], sortasc: "ASC" };
      sortasc = "ASC";
    } else {
      for (let i = 0; i < _headTable.length; i++) {
        _headTable[i].sortasc = "";
      }
      _headTable[index] = { ..._headTable[index], sortasc: "DESC" };
      sortasc = "DESC";
    }

    setHeadTable(_headTable);
    props.sortCallback(sortby, sortasc);
  };

  return (
    // (stateTable) ? (
    <div className={"insane-table " + props.className}>
      <div className="box-table" style={{ overflow: "unset" }}>
        <table>
          <thead>
            <tr>
              <th style={{ minWidth: 30 }}></th>
              {headTable.map((header, header_index) => (
                <th
                  key={header_index}
                  style={{ cursor: "pointer" }}
                  onClick={() => onSort(header.attName, header_index)}
                >
                  {header.displayName}
                  {header.sortasc && header.sortasc === "DESC"
                    ? " ▼"
                    : header.sortasc && header.sortasc === "ASC"
                    ? " ▲"
                    : " -"}
                </th>
              ))}
              {tableOption.action.length > 0 ? <th>ACTION</th> : null}
            </tr>
          </thead>
          <tbody>
            {dataTable.map((row, i) => (
              <tr key={i} style={{ height: 30 }}>
                <td>{i + 1}</td>
                {headTable.map((col, j) => {
                  if (col.type === "id") {
                    if (col.form.element === "select") {
                      return (
                        <td key={j}>
                          {getValueById(
                            "value",
                            col.form.option,
                            row[col.attName]
                          )}
                        </td>
                      );
                    } else {
                      return <td key={j}>{row[col.attName]}</td>;
                    }
                  } else if (col.type === "text") {
                    return <td key={j}>{row[col.attName]}</td>;
                  } else if (col.type === "number") {
                    return <td key={j}>{parseInt(row[col.attName])}</td>;
                  } else if (col.type === "float") {
                    return (
                      <td key={j}>{parseFloat(row[col.attName]).toFixed(2)}</td>
                    );
                  } else if (col.type === "bt-view-image") {
                    if (row[col.attName]) {
                      return (
                        <td key={j}>
                          <ModalViewImage
                            data={row}
                            titleButton="View"
                          ></ModalViewImage>
                        </td>
                      );
                    } else {
                      return (
                        <td key={j} style={{ color: "#CCCCCC" }}>
                          No Image
                        </td>
                      );
                    }
                  } else if (col.type === "date") {
                    return <td key={j}>{ConvertDate(row[col.attName])}</td>;
                  } else if (col.type === "approve") {
                    if (row[col.attName]) {
                      return (
                        <td key={j} style={{ color: "#1FD606" }}>
                          Approved
                        </td>
                      );
                    } else {
                      return (
                        <td key={j} style={{ color: "#CCCCCC" }}>
                          Not Approved
                        </td>
                      );
                    }
                  } else if (col.type === "boolean") {
                    if (row[col.attName]) {
                      return (
                        <td key={j}>
                          <img src={ic_true} alt="ic_true" />
                        </td>
                      );
                    } else {
                      return (
                        <td key={j}>
                          <img src={ic_false} alt="ic_false" />
                        </td>
                      );
                    }
                  } else if (col.type === "cover") {
                    if (row[col.attName]) {
                      return (
                        <td key={j} style={{ color: "#1FD606" }}>
                          Cover
                        </td>
                      );
                    } else {
                      return (
                        <td key={j} style={{ color: "#CCCCCC" }}>
                          -
                        </td>
                      );
                    }
                  } else if (col.type === "status-approve") {
                    if (row[col.attName]) {
                      if (row[col.attName] === "APPROVE") {
                        return (
                          <td
                            key={j}
                            style={{ color: "#1fd606", fontWeight: "bolder" }}
                          >
                            {row[col.attName]}
                          </td>
                        );
                      } else if (row[col.attName] === "REJECT") {
                        return (
                          <td
                            key={j}
                            style={{ color: "red", fontWeight: "bolder" }}
                          >
                            {row[col.attName]}
                          </td>
                        );
                      } else {
                        return <td key={j}>{row[col.attName]}</td>;
                      }
                    } else {
                      return (
                        <td key={j} style={{ color: "#CCCCCC" }}>
                          -
                        </td>
                      );
                    }
                  } else {
                    return null;
                  }
                })}
                {tableOption.action.length > 0 ? (
                  <td>
                    <div style={{ display: "flex" }}>
                      {tableOption.action.map((action, action_index) => {
                        if (action.mode === "page") {
                          return (
                            <button
                              key={action_index}
                              className="custom-input bt_active bt_search"
                              onClick={() =>
                                onPageNavigate(action.option.url, row)
                              }
                            >
                              {action.name}
                            </button>
                          );
                        } else if (action.mode === "modal-form") {
                          return (
                            <Modal
                              key={action_index}
                              type={action.option.type}
                              api={tableOption.api}
                              data={row}
                              titleButton={action.name}
                              mapTable={headTable}
                            ></Modal>
                          );
                        } else if (action.mode === "modal-form-action") {
                          return (
                            <ModalAction
                              key={action_index}
                              type={action.option.type}
                              reload={() => props.reload()}
                              api={tableOption.api}
                              data={row}
                              titleButton={action.name}
                              mapTable={headTable}
                            ></ModalAction>
                          );
                        } else {
                          return null;
                        }
                      })}
                    </div>
                  </td>
                ) : null}
              </tr>
            ))}
            {Array.from(Array(dataTableMissRow).keys()).map((row, i) => (
              <tr key={i} style={{ height: 30 }}>
                {headTable.map((col, j) => (
                  <td key={j}></td>
                ))}
                {tableOption.action.length > 0 ? <td></td> : null}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
}

export default InsaneTableNoHttp;

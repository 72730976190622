import React, { useEffect, useRef, ReactElement } from "react";

import { Wrapper, Status } from "@googlemaps/react-wrapper";
import Spinner from "../../component/spinner";

function ErrorComponent() {
  return <div>Error</div>;
}

const render = (status) => {
  switch (status) {
    case Status.LOADING:
      return <Spinner />;
    case Status.FAILURE:
      return <ErrorComponent />;
    case Status.SUCCESS:
      return <MyMapComponent />;
  }
};

function createMarker(marker, map) {
  //   console.log("createMarker : ", map);
  return new window.google.maps.Marker({
    position: marker.position,
    map: map,
    icon: {
      url: marker.icon, // url
      scaledSize: new window.google.maps.Size(40, 75), // scaled size
    },
  });
}

function createPolygon(polygon, map) {
  //   console.log("createPolygon : ", map);
  return new window.google.maps.Polygon({
    paths: polygon.path,
    map: map,
    position: polygon.position,
    fillColor: polygon.color,
    fillOpacity: 0.1,
    strokeColor: polygon.color,
    strokeWeight: 2,
  });
}

function createPolyline(polygon, map, zindex) {
  return new window.google.maps.Polyline({
    path: polygon.path,
    map: map,
    position: polygon.position,
    strokeColor: polygon.color_line ? polygon.color_line : polygon.color,
    strokeWeight: 10,
    zIndex: zindex
  });
}

function MyMapComponent(props) {
  const [MapState, setMapState] = React.useState(null);

  const refMap = useRef();

  const refMarkers = React.useRef([]);
  const refPolygons = React.useRef([]);
  const refPolylines = React.useRef([]);
  const refInfowindow = React.useRef([]);

  const refHighlightPolylines = React.useRef([]);

  function clearMap(Ref) {
    for (let R of Ref) {
      R.setMap(null);
    }
  }

  function genInfoWindowContent(obj) {
    console.log("obj.label ---> ", obj.label);
    let content = "";
    if (obj.label === "road_distress") {
      content = `
        <img src="` + obj.row_data.img_url + `" style="width:300px">
        <br/><span>CS Name : </span><span style="font-weight: bolder">` + obj.row_data.cs_name + `</span>
        <br/><span>IRR No. : </span><span style="font-weight: bolder">` + obj.row_data.irrno + `</span>
        <br/><span>Report Code : </span><span style="font-weight: bolder">` + obj.row_data.report_code + `</span>
        <br/><span>Report Label : </span><span style="font-weight: bolder">` + obj.row_data.report_label + `</span>
        <br/><span>Km : </span><span style="font-weight: bolder">` + obj.row_data.km + `
        <br/><span>Position : </span><span style="font-weight: bolder">` + obj.row_data.latitude + `,` + obj.row_data.longitude + `
        <a href="https://www.google.com/maps/search/?api=1&query=` + obj.row_data.latitude + `,` + obj.row_data.longitude + ` " target="_blank"> See </a></span>
        <br/><span>surface : </span><span style="font-weight: bolder">` + obj.row_data.surface + `</span>
        <br/><span>update_time : </span><span style="font-weight: bolder">` + obj.row_data.update_time + `</span>
      `
    }
    
    if (obj.label === "falling") {
      content = `
        <br/><span>Control section : </span><span style="font-weight: bolder">` + obj.row_data.cs_no + `</span>
        <br/><span>Road No. : </span><span style="font-weight: bolder">` + obj.row_data.road_no + `</span>
        <br/><span>Detection No : </span><span style="font-weight: bolder">` + obj.row_data.fallingdetectionno + `</span>
        <br/><span>User ID : </span><span style="font-weight: bolder">` + obj.row_data.userid + `</span>
        <br/><span>Position : </span><span style="font-weight: bolder">` + obj.row_data.latitude + `,` + obj.row_data.longitude + `
        <a href="https://www.google.com/maps/search/?api=1&query=` + obj.row_data.latitude + `,` + obj.row_data.longitude + ` " target="_blank"> See </a></span>
      `
    }
    
    if (obj.label === "user") {
      content = `
        <span>User ID : </span><span style="font-weight: bolder">` + obj.row_data.userid + `</span>
        <br/><span>Mobile No. : </span><span style="font-weight: bolder">` + obj.row_data.mobileno + `</span>
      `
    }
    
    if (obj.label === "line") {
      console.log("line");
      content = `
        <span>Control section : </span><span style="font-weight: bolder">` + obj.row_data.cs_no + `</span>
        <br/><span>Road No. : </span><span style="font-weight: bolder">` + obj.row_data.road_no + `</span>
        <br/><span>CS Name : </span><span style="font-weight: bolder">` + obj.row_data.cs_name + `</span>
      `
    }
    
    if (obj.label === "status") {
      console.log("status");
      content = `
        <span>Control section : </span><span style="font-weight: bolder">` + obj.row_data.cs_no + `</span>
        <br/><span>Road No. : </span><span style="font-weight: bolder">` + obj.row_data.road_no + `</span>
        <br/><span>IRI : </span><span style="font-weight: bolder; color: ` + obj.color + `; font-size: 18px">` + obj.row_data.iri + `</span>
        <br/><span>surface : </span><span style="font-weight: bolder">` + obj.row_data.surface + `</span>
        <br/><span>update_time : </span><span style="font-weight: bolder">` + obj.row_data.update_time + `</span>
      `
    }

    return content;
  }

  useEffect(() => {
    if (MapState) {
      clearMap(refMarkers.current);
      if (props.markers.length > 0) {
        for (let i = 0; i < props.markers.length; i++) {
          let M = props.markers[i];
          const marker = createMarker(M, MapState);
          // if (M.label === "road_distress") {
            marker.addListener("click", () => {
              // console.log(M.name);
              clearMap(refInfowindow.current);
              const infowindow = new window.google.maps.InfoWindow({
                content: genInfoWindowContent(M),
              });
              refInfowindow.current.push(infowindow);
              infowindow.open({ anchor: marker, MapState });
            });
          // }
          refMarkers.current.push(marker);
        }
      }
    }
  }, [props.markers]);

  useEffect(() => {
    if (MapState) {
      clearMap(refPolygons.current);
      if (props.polygons.length > 0) {
        for (let i = 0; i < props.polygons.length; i++) {
          let P = props.polygons[i];
          const polygon = createPolygon(P, MapState);
          refPolygons.current.push(polygon);
        }
      }
    }
  }, [props.polygons]);

  useEffect(() => {
    if (MapState) {
      clearMap(refPolylines.current);
      if (props.polylines.length > 0) {
        for (let i = 0; i < props.polylines.length; i++) {
          let P = props.polylines[i];
          const polyline = createPolyline(P, MapState, (i + 100));
          polyline.addListener("click", () => {
            console.log(P);

            clearMap(refInfowindow.current);
            clearMap(refHighlightPolylines.current);
            console.log( parseInt(props.polylines.length) + 100)
            const highlight_polyline = createPolyline({...P, color_line: "#6dfaff"}, MapState, ( parseInt(props.polylines.length) + 101 ) );
            refHighlightPolylines.current.push(highlight_polyline);

            const infowindow = new window.google.maps.InfoWindow({
              content: genInfoWindowContent(P),
            });

            refInfowindow.current.push(infowindow);
            infowindow.open({ anchor: polyline, MapState });

            new window.google.maps.event.addListener(infowindow,'closeclick',function(){
              clearMap(refHighlightPolylines.current);
            });

          });

          refPolylines.current.push(polyline);
        }
      }
    }
  }, [props.polylines]);

  useEffect(() => {
    let create_map = new window.google.maps.Map(refMap.current, {
      center: props.center,
      zoom: props.zoom,
    });

    setMapState(create_map);

    window.google.maps.event.addListener(create_map, "idle", function (event) {
      var bounds = create_map.getBounds();
      //   console.log(bounds);
      props.boundsChange(bounds);
    });
  }, []);

  return (
    <div ref={refMap} id="map" style={{ width: "100%", height: "100%" }} />
  );
}

function MyApp(props) {
  const center = props.center ? props.center : { lat: -34.397, lng: 150.644 };
  const zoom = props.zoom ? props.zoom : 4;
  const markers = props.markers ? props.markers : [];
  const polylines = props.polylines ? props.polylines : [];
  const polygons = props.polygons ? props.polygons : [];
  const distance = props.distance ? props.distance * 100 : 2000;

  return (
    <Wrapper apiKey="AIzaSyB_3_2OR5g5HWfZ6iheW_xOXn088wGkaVc" render={render}>
      <MyMapComponent
        center={center}
        zoom={zoom}
        markers={markers}
        polylines={polylines}
        polygons={polygons}
        distance={distance}
        boundsChange={props.callBack}
      />
    </Wrapper>
  );
}

export default MyApp;

import "./styles.css";
import React from "react";
import { HttpRequest } from "../../component/service/HttpRequest";
import InsaneTableNoHttp from "../../component/insane-table-no-http";
import Spinner from "../../component/spinner";
import InsaneInputSearch from "../../component/insane-input-search";

function App() {
  const [loading, setLoading] = React.useState(true);

  const [dataTable, setDataTable] = React.useState([]);

  const [limitRow, setLimitRow] = React.useState(10);
  const [currentPage, setCurrentPage] = React.useState(1);
  const [totalPage, setTotalPage] = React.useState(0);

  const [distanceQueryRoad, setDistanceQueryRoad] = React.useState(0);

  const [centerPositionQueryRoad, setCenterPositionQueryRoad] = React.useState({
    latitude: 13.84283,
    longitude: 100.67356,
  });

  const [searchText, setSearchText] = React.useState("");

  const [searchInputDepartment, setSearchInputDepartment] = React.useState("");
  const [searchInputDistrict, setSearchInputDistrict] = React.useState("");
  const [searchInputDivision, setSearchInputDivision] = React.useState("");

  const tableOption = {
    tableName: "Road Detail Table",
    className: "",
    mapTable: [
      {
        displayName: "หมายเลขสายทาง",
        attName: "road_no",
        type: "text",
        form: { validate: true, element: "input" },
      },
      {
        displayName: "ชื่อตอนควบคุม",
        attName: "road_name",
        type: "text",
        form: { validate: true, element: "input" },
      },
      {
        displayName: "หมวดทางหลวง",
        attName: "dep_name",
        type: "text",
        form: { validate: true, element: "input" },
      },
      {
        displayName: "จำนวนตอนย่อย",
        attName: "tota_segment",
        type: "number",
        form: { validate: true, element: "input" },
      },
      {
        displayName: "ค่าเฉลี่ยดัชนีความขรุขระ (IRI)",
        attName: "avg_roadiri",
        type: "float",
        form: { validate: true, element: "input" },
      },
      {
        displayName: "จำนวนความเสียหายของถนน",
        attName: "total_roaddistress",
        type: "number",
        form: { validate: true, element: "input" },
      },
      {
        displayName: "ระยะทางรวม (กม.)",
        attName: "total_distance_km",
        type: "float",
        form: { validate: true, element: "input" },
      },
      // {
      //   displayName: "ระยะทางสำรวจสะสม (กม.)",
      //   attName: "survey_distance_km",
      //   type: "float",
      //   form: { validate: true, element: "input" },
      // },
      {
        displayName: "ถนนอันตราย (ช่วง)",
        attName: "rci_fail_total",
        type: "number",
        form: { validate: true, element: "input" },
      },
      {
        displayName: "จำนวนความเสียหาย/กิโลเมตร",
        attName: "roadistress_per_km",
        type: "float",
        form: { validate: true, element: "input" },
      },
    ],
    action: [
      // {
      //   name: "ตรวจสอบรายละเอียด",
      //   mode: "page", /// page, modal, form
      //   option: {
      //     url: "",
      //   },
      // },
    ],
  };

  async function onRequest(state = "") {
    setDataTable([]);
  }

  async function onSearchTable() {
    setLoading(true);

    await onGetRoadTable();

    setLoading(false);
  }

  async function onGetRoadTable(sortby = "", sortasc = "ASC") {
    let param = {
      latitude: centerPositionQueryRoad.latitude,
      longitude: centerPositionQueryRoad.longitude,
      distancekm: distanceQueryRoad,
      index: 0,
      count: limitRow,
      search: searchText,
      sortby: sortby, //road_no , road_name , tota_segment , iri_avg , total_roaddistress , total_distance_km , rci_fail_total , roadistress_per_km
      sortasc: sortasc, // ASC , DESC

      depot_code:
        searchInputDepartment && searchInputDepartment !== ""
          ? searchInputDepartment
          : "",
      district_code:
        searchInputDistrict && searchInputDistrict !== ""
          ? searchInputDistrict
          : "",
      division_code:
        searchInputDivision && searchInputDivision !== ""
          ? searchInputDivision
          : "",
    };

    await HttpRequest("/admin/getroadlist", param).then((data) => {
      console.log("HttpRequest getroadlist :", data);
      if (data.result === "OK") {
        setDataTable(data.roadlist.rows);
        let total_page = Math.ceil(data.roadlist.rows.length / limitRow);
        setTotalPage(total_page);
      }
    });

    // await HttpRequest("/admin/getdeplist", {}).then((data) => {
    //   console.log("HttpRequest getdeplist :", data);
    // });
    // await HttpRequest("/admin/getdislist", {}).then((data) => {
    //   console.log("HttpRequest getdislist :", data);
    // });
    // await HttpRequest("/admin/getdivilist", {}).then((data) => {
    //   console.log("HttpRequest getdivilist :", data);
    // });
  }

  function onChangeQueryRoad() {}

  async function onRequest() {
    setLoading(true);

    await onGetRoadTable();

    setLoading(false);
  }

  function onNext() {
    let _page = currentPage + 1;
    if (_page <= totalPage) {
      setCurrentPage(_page);
    }
  }

  function onPre() {
    let _page = currentPage - 1;
    if (_page > 0) {
      setCurrentPage(_page);
    }
  }

  React.useEffect(() => {
    const controller = new AbortController();
    onRequest();
    return () => {
      controller.abort();
    };
  }, []);

  // React.useEffect(() => {
  //   const controller = new AbortController();
  //   console.log("searchInputDepartment by --> ", searchInputDepartment);
  //   return () => {
  //     controller.abort();
  //   };
  // }, [searchInputDepartment]);

  const onSort = React.useCallback((sortby, sortasc) => {
    console.log("onSort");
    onGetRoadTable(sortby, sortasc);
  }, []);

  return (
    <>
      {loading ? (
        <div
          className="container-content-manage-road"
          style={{ alignItems: "center", justifyContent: "center" }}
        >
          <Spinner></Spinner>
          <div>กำลังโหลด</div>
        </div>
      ) : null}
      <div className="main-content">
        <div className="container-content-manage-road">
          <div className="table-container-manage-road">
            <div className="table-container-manage-road-title">
              ตารางแสดงรายละเอียดถนน
            </div>

            <div className="table-container-manage-road-limit-row">
              <span>แสดง</span>
              <input
                className="form-control"
                type="number"
                style={{ marginRight: 20 }}
                value={limitRow}
                onChange={(e) => setLimitRow(e.target.value)}
              ></input>
              <span>รายการ</span>
              <div
                style={{
                  flexGrow: 1,
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "end",
                  flexWrap: "wrap",
                  padding: "0px 20px",
                }}
              >
                <InsaneInputSearch
                  api="/admin/getdivilist"
                  param={{}}
                  type="text"
                  label="เลือกสำนักงานทางหลวง"
                  attDisplay="divi_name"
                  attValue="division_code"
                  value={setSearchInputDivision}
                  placeholder="ค้นหา.."
                ></InsaneInputSearch>
                <InsaneInputSearch
                  api="/admin/getdislist"
                  param={{
                    division_code: searchInputDivision,
                  }}
                  type="text"
                  label="เลือกแขวงทางหลวง"
                  attDisplay="dis_name"
                  attValue="district_code"
                  value={setSearchInputDistrict}
                  placeholder="ค้นหา.."
                ></InsaneInputSearch>
                <InsaneInputSearch
                  api="/admin/getdeplist"
                  param={{
                    district_code: searchInputDistrict,
                    division_code: searchInputDivision,
                  }}
                  type="text"
                  label="เลือกหมวดทางหลวง"
                  attDisplay="dep_name"
                  attValue="depot_code"
                  value={setSearchInputDepartment}
                  placeholder="ค้นหา.."
                ></InsaneInputSearch>
                <input
                  type="text"
                  placeholder="ค้นหาสายถนน"
                  className="form-control"
                  style={{
                    width: "unset",
                    flexGrow: 1,
                    height: 30,
                    minWidth: 260,
                    textAlign: "left",
                    padding: "0px 20px",
                    margin: "5px 15px",
                    background: "#FFF",
                    border: "none",
                    boxShadow: "1px 1px 3px 0px rgba(0, 0, 0, 0.5)",
                  }}
                  value={searchText}
                  onChange={(event) => setSearchText(event.target.value)}
                />
              </div>
              {/* <span>จุดดึงข้อมูล</span>
                <button
                  onClick={onChangeQueryRoad}
                  className="form-control"
                  style={{ marginRight: 20, marginLeft: 20, width: "unset" }}
                >
                  <img style={{ width: 25, marginRight: 10 }} src={pinDrop} />
                  {"[ "}
                  {centerPositionQueryRoad.latitude},{" "}
                  {centerPositionQueryRoad.longitude}
                  {" ]"} ระยะ {distanceQueryRoad} กิโลเมตร
                </button> */}
              {/* <div>Page</div>
                <button onClick={onPre}>&#10094;</button>
                <input
                  type="number"
                  value={currentPage}
                  onChange={(e) => {
                    if (e.target.value > totalPage) {
                      setCurrentPage(parseInt(totalPage));
                    } else if (e.target.value < 1) {
                      setCurrentPage(1);
                    } else {
                      setCurrentPage(parseInt(e.target.value));
                    }
                  }}
                ></input>
                <div> / {totalPage}</div>
                <button onClick={onNext}>&#10095;</button> */}
              <button style={{ minWidth: 100 }} onClick={onSearchTable}>
                ค้นหา
              </button>
            </div>
            <div className="table-container-manage-road-content">
              {/* <GoogleMapReact
                  bootstrapURLKeys={{
                    key: "AIzaSyB_3_2OR5g5HWfZ6iheW_xOXn088wGkaVc",
                  }}
                ></GoogleMapReact> */}
              <InsaneTableNoHttp
                className=""
                option={tableOption}
                dataTable={dataTable}
                sortCallback={onSort}
              ></InsaneTableNoHttp>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default App;

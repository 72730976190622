import React, { createContext, useContext, useState } from 'react';

// Create the context
const AuthContext = createContext();

// Create a provider for the context
function AuthProvider({ children }) {

  const [user, setUser] = useState(null);

  function login(authenData) {
    setUser(authenData.user);
  }

  function logout() {
    setUser(null);
  }

  return (
    <AuthContext.Provider value={{ user, login, logout }}>
      {children}
    </AuthContext.Provider>
  );
}

// Create a hook that allows you to access the context
function useAuth() {
  return useContext(AuthContext);
}

export { AuthProvider, useAuth };
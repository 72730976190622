import "./styles.css";
import React from "react";
import { HttpRequest } from "../../component/service/HttpRequest";
import logoMap from "../../assets/images/map.png";
import logoRoad from "../../assets/images/road.png";
import SummaryBox from "../../component/summary-box";
import Spinner from "../../component/spinner";

function App() {

  const [loading, setLoading] = React.useState(true);

  const [summaryData, setSummaryData] = React.useState([
    {
      title: "จำนวนผู้ใช้งาน Smooth Ride App",
      value: "",
      total: "",
      path: "",
    },
    {
      title: "จำนวนการแจ้งเตือนรถล้ม",
      value: "",
      total: "",
      path: "",
    },
    {
      title: "ถนนที่สำรวจ / ถนนทั้งหมด",
      value: "",
      total: "",
      path: "",
    },
    // {
    //   title: "ระยะทางสำรวจ / ระยะทางทั้งหมด (กม.)",
    //   value: "",
    //   total: "",
    //   path: "",
    // },
    {
      title: "ระยะทางที่ขรุขระ (IRI > 3.5) (กม.)",
      value: "",
      total: "",
      path: "",
    },
    {
      title: "ความเสียหายของถนน",
      value: "",
      total: "",
      path: "",
    },
  ]);

  function formateValueDisplay(value) {
    let IntValue = parseInt(value);
    let StrValue = IntValue.toLocaleString();
    return StrValue;
  }

  async function onGetDashboard() {
    await HttpRequest("/admin/getdashboard", {}).then((data) => {
      console.log("HttpRequest getdashboard :", data);
      if (data.result === "OK") {
        let newSummary = [
          {
            title: "จำนวนผู้ใช้งาน Smooth Ride App",
            value: formateValueDisplay(data.roaddata.total_user),
            total: "",
            path: "",
          },
          {
            title: "จำนวนการแจ้งเตือนรถล้ม",
            value: formateValueDisplay(data.roaddata.survey_fallingdetection),
            total: "",
            path: "",
          },
          {
            title: "ถนนที่สำรวจ / ถนนทั้งหมด",
            value: formateValueDisplay(data.roaddata.survey_road),
            total: formateValueDisplay(data.roaddata.total_road),
            path: "",
          },
          // {
          //   title: "ระยะทางสำรวจ / ระยะทางทั้งหมด (km)",
          //   value: formateValueDisplay(data.roaddata.survey_distance_km),
          //   total: formateValueDisplay(data.roaddata.total_distance_km),
          //   path: "",
          // },
          {
            title: "ระยะทางที่ขรุขระ (IRI > 3.5) (km)",
            value: formateValueDisplay(data.roaddata.survey_distance_km_35),
            total: formateValueDisplay(data.roaddata.total_distance_km),
            path: "",
          },
          {
            title: "ความเสียหายของถนน (จุด)",
            value: formateValueDisplay(data.roaddata.total_roaddistress),
            total: "",
            path: "",
          },
        ];
        setSummaryData(newSummary);
      }
    });
  }


  async function onRequest() {
    setLoading(true);

    await onGetDashboard();

    setLoading(false);
  }

  React.useEffect(() => {
    const controller = new AbortController();
    onRequest();
    return () => {
      controller.abort();
    };
  }, []);

  return (
    <>
      {loading ? (
        <div
          className="container-content-dashboad"
          style={{ alignItems: "center", justifyContent: "center" }}
        >
          <Spinner></Spinner>
          <div>กำลังโหลด</div>
        </div>
      ) : (
        <div className="main-content">

          <div className="container-title">
            <div className="title">ภาพรวม</div>
            <div className="subtitle-container">
              <div className="subtitle">วันที่ปรับปรุงข้อมูลล่าสุด</div>
              <div className="subtitle-date">14 พฤศจิกายน 2565</div>
            </div>
          </div>

          <div className="container-content-dashboad">

            <div className="summary-container-dashboad" style={{marginTop: 20, marginBottom: 20}}>
              <SummaryBox title="แผนที่แสดงเส้นทาง" path="/realtime">
                <img className="logo-map" src={logoMap} />
              </SummaryBox>
              <SummaryBox title="จัดการถนน" path="/manage-road">
                <img className="logo-map" src={logoRoad} />
              </SummaryBox>
            </div>

            <div className="summary-container-dashboad">
              {summaryData.map((summary, index) => {
                return (
                  <SummaryBox
                    key={index}
                    title={summary.title}
                    path={summary.path}
                  >
                    <div className="value">{summary.value}</div>
                    {summary.total && summary.total !== "" ? (
                      <div className="valueTotal">{summary.total}</div>
                    ) : null}
                  </SummaryBox>
                );
              })}
            </div>

          </div>
        </div>
      )}
    </>
  );
}

export default App;


import './styles.css';

//////////// REACT /////////////
import React from "react";

//////////// REDUX | STORE | SERVICE /////////////
import { HttpRequest } from '../../../service/HttpRequest';

//////////// COMPONENT /////////////
import { Modal, Button, InputGroup, FormControl, FormSelect } from 'react-bootstrap';

export function App(props) {
  // console.log(props);
  const [show, setShow] = React.useState(false);
  const [titleButtonChange, setTitleButtonChange] = React.useState("");
  const hasFetchedData = React.useRef(false);

  // const [headTable, setHeadTable] = React.useState(props.mapTable);

  // let dataObject = props.data;
  const [dataObject, setDataObject] = React.useState(props.data);

  let keyObject = Object.keys(props.api[props.type].dataReq);

  const reload = () => props.reload();

  const InitialTitleButtonChange = React.useCallback(async () => {
    if (!hasFetchedData.current) {
      if (props.type === "update") {
        setTitleButtonChange("Save Changes");
      } else if (props.type === "insert") {
        setTitleButtonChange("Add");
      }
      hasFetchedData.current = true;
    }
  }, [props]);

  React.useEffect(() => {
    InitialTitleButtonChange();
  }, [InitialTitleButtonChange]);

  const getDisplayName = (att_name) => {
    let find_table_map = props.mapTable.filter(_table_map => {
      return _table_map.attName === att_name;
    })
    if (find_table_map.length > 0) {
      return find_table_map[0].displayName
    } else {
      return att_name
    }
  }

  const getInputElementName = (att_name) => {
    let find_table_map = props.mapTable.filter(_table_map => {
      return _table_map.attName === att_name;
    })
    if (find_table_map.length > 0) {
      return find_table_map[0].form.element
    } else {
      return "input"
    }
  }

  const getInputElementOptionSelect = (att_name) => {
    let find_table_map = props.mapTable.filter(_table_map => {
      return _table_map.attName === att_name;
    })
    if (find_table_map.length > 0) {
      return find_table_map[0].form.option
    } else {
      return []
    }
  }
  

  const getInputElementDisable = (att_name) => {
    let find_table_map = props.mapTable.filter(_table_map => {
      return _table_map.attName === att_name;
    })
    if (find_table_map.length > 0) {
      return !(find_table_map[0].form.enable)
    } else {
      return false
    }
  }

  const onSaveChange = () => {

    // console.log(dataObject);

    HttpRequest(props.api[props.type].url, dataObject).then(data => {
      // console.log(data);
      if (data.result === "OK") {
        reload();
        handleClose();
      } else {
        alert("Update Fail!!");
      }
    });

  }

  const onDelete = () => {
    let dataDelete = props.api.delete.dataReq;
    let dataDeleteKey = Object.keys(dataDelete);
    for (let i = 0; i < dataDeleteKey.length; i++) {
      const key = dataDeleteKey[i];
      dataDelete[key] = dataObject[key];
    }
    console.log(dataDelete);
    HttpRequest(props.api.delete.url, dataDelete).then(data => {
      console.log(data);
      if (data.result === "OK") {
        reload();
        handleClose();
      } else {
        alert("Delete Fail!!");
      }
    });

  }

  const handleClose = () => { setShow(false) };
  const handleShow = () => setShow(true);

  return (
    <>
      <Button variant="primary" onClick={handleShow}>
        {props.titleButton}
      </Button>

      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>{props.titleButton}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {
            keyObject.map((data, index) => {
              return (
                <InputGroup key={index} className="custom-style-InputGroup">
                  <InputGroup.Text id="inputGroup-sizing-default" className='InputGroupTitle'>{getDisplayName(data)}</InputGroup.Text>

                  {
                    (getInputElementName(data) === "input") ?
                      <FormControl
                        aria-label="Default"
                        aria-describedby="inputGroup-sizing-default"
                        style={{ boxShadow: "none" }}
                        defaultValue={dataObject[data]}
                        disabled={getInputElementDisable(data)}
                        onChange={(event) => {

                          let dataChange = props.api[props.type].dataReq;
                          for (let i = 0; i < keyObject.length; i++) {
                            const key = keyObject[i];
                            if (data === key) {
                              dataChange[key] = event.target.value;
                            } else {
                              dataChange[key] = dataObject[key];
                            }
                          }
                          setDataObject(dataChange);
                        }}
                      />
                      :
                      (null)
                  }

                  {
                    (getInputElementName(data) === "select") ?
                      <FormSelect
                        aria-label="Default"
                        aria-describedby="inputGroup-sizing-default"
                        style={{ boxShadow: "none", height: 40, margin: 5, marginLeft: -1 }}
                        defaultValue={dataObject[data]}
                        disabled={getInputElementDisable(data)}
                        onChange={(event) => {

                          let dataChange = props.api[props.type].dataReq;
                          for (let i = 0; i < keyObject.length; i++) {
                            const key = keyObject[i];
                            if (data === key) {
                              dataChange[key] = event.target.value;
                            } else {
                              dataChange[key] = dataObject[key];
                            }
                          }
                          setDataObject(dataChange);

                        }}>

                        <option value="">( unset )</option>

                        {
                          (getInputElementOptionSelect(data)).map((j_data, j) => {
                            return <option key={j} value={j_data.value}>{j_data.displayName}</option>
                          })
                        }
                      </FormSelect>
                      :
                      (null)
                  }

                </InputGroup>);
            })
          }
        </Modal.Body>
        <Modal.Footer>
          {
            props.api.delete.enable && props.type !== "insert" ?
              <Button variant="danger" onClick={onDelete}>Delete</Button>
              : (null)
          }
          <div style={{ flexGrow: 1 }}></div>
          <Button variant="secondary" onClick={handleClose}>Close</Button>
          <Button variant="primary" onClick={onSaveChange}>{titleButtonChange}</Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default App;
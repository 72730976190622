import "./styles.css";
import React from "react";
import { HttpRequest } from "../../component/service/HttpRequest";

function InsaneInputSearch(props) {
  const [textSearch, setTextSearch] = React.useState("");
  const [resultSearch, setResultSearch] = React.useState([]);
  const [resultState, setResultState] = React.useState(false);
  
  const [resultValue, setResultValue] = React.useState(null);

  async function onSearch(text = textSearch) {
    setResultSearch([]);

    await HttpRequest(props.api, { search: text, ...props.param }).then((data) => {
        console.log(data);
      let key_result = Object.keys(data);
      if (data.result === "OK") {
        setResultSearch(data[key_result[1]].rows);

        if (data[key_result[1]].rows.length > 0) {
          setResultState(true);
        }
      }
    });
  }

  const onSetSelectValue = (list) => {

    setResultValue(list[props.attDisplay]);
    props.value(list[props.attValue]);

    setResultState(false);
  };
  

  const onClearValue = () => {

    setTextSearch("")
    setResultValue("");
    props.value("");

    setResultState(false);
  };

  const onToggle = () => {
    if (resultState) {
        setResultState(false);
    } else {
        setResultState(true);
        onSearch();
    }
  };

  React.useEffect(() => {
    if (textSearch && (textSearch !== "")) {
      onSearch(textSearch);
      setResultState(true);
    }
  }, [textSearch]);

  return (
    <div className="container">
      <div className="insane-input-search" onClick={() => onToggle()}> { resultValue ? resultValue : props.label } </div>
      <div className={"select-content " + (resultState ? "active" : "")}>
        <div className="header">
          <input
            className="input"
            type={props.type}
            placeholder={props.placeholder}
            value={textSearch}
            onChange={(event) => setTextSearch(event.target.value)}
          />
          <button className="bt" onClick={() => onClearValue()}>C </button>
          <button className="bt" onClick={() => setResultState(false)}> X </button>
        </div>
        {resultSearch.map((searchList, index) => {
          return (
            <div
              className="list"
              key={index}
              onClick={() => onSetSelectValue(searchList)}
            >
              {searchList[props.attDisplay]}
            </div>
          );
        })}
      </div>
    </div>
  );
}

export default InsaneInputSearch;

import "./styles.css";
import React from "react";
import { useNavigate } from "react-router-dom";

import menuIcon from "../../assets/icons/menu.png";
import closeIcon from "../../assets/icons/close.png";
import notiIcon from "../../assets/icons/noti.png";

import { HttpRequest } from "../service/HttpRequest";
import { deleteCookie } from "../service/CookieManagement";
import { useAuth } from "../service/Authentication";

function Navbar(props) {
  let menuList = props.menuList;

  const [stateMenu, setStateMenu] = React.useState(false);
  const [stateNotiList, setStateNotiList] = React.useState(false);

  const [notiList, setNotiList] = React.useState([]);
  const [notiAlert, setAlert] = React.useState(false);

  const [timerRefresh, setTimerRefresh] = React.useState(0);

  const AuthContext = useAuth();
  const navigate = useNavigate();

  const onLogOut = () => {
    // let usertoken = getCookie("smooth_ride_backend_login_token");

    HttpRequest("/admin/logout", {}).then((data) => {
      console.log(data);
      if (data.result === "OK") {
        deleteCookie("smooth_ride_backend_login_user");
        deleteCookie("smooth_ride_backend_login_token");
        AuthContext.logout();
      }
    });
  };

  async function onGetNotification(state = "") {
    let param = {
      latitude: 13.84283,
      longitude: 100.67356,
      distancekm: 2000,
      starttime: null,
      stoptime: null,
      index: null,
      count: null,
    };

    await HttpRequest("/admin/getrecentfallingdetectionlist", param).then(
      (data) => {
        console.log("HttpRequest getrecentfallingdetectionlist :", data);
        if (data.result === "OK") {
          setNotiList(data.getrecentfallingdetectionlist);
          setAlert(true);
        }
      }
    );
  }

  const onNavigatePage = (path) => {
    // console.log("onNavigatePage : ", path);
    navigate(path);
    setStateMenu(false);
  };

  const onReadAllNoti = () => {
    let _notis = [...notiList];
    for (let i = 0; i < _notis.length; i++) {
      _notis[i].read = true;
    }
    setNotiList(_notis);
  };

  const onReadByIndex = (index) => {
    let _notis = [...notiList];
    _notis[index].read = true;
    setNotiList(_notis);
  };

  const onGetNotiCount = () => {
    let countNoRead = notiList.filter((noti) => noti.read === false);
    return countNoRead.length;
  };

  const getTimeWording = (time) => {
    let startDate = new Date(time);
    // Do your operations
    let endDate = new Date();
    let seconds = (endDate.getTime() - startDate.getTime()) / 1000;
    let minute = seconds / 60;
    let hour = minute / 60;
    let day = hour / 24;

    if (hour > 23) {
      return "เมื่อ " + parseInt(day) + " วันที่แล้ว";
    } else if (minute > 59) {
      return "เมื่อ " + parseInt(hour) + " ชั่วโมงที่แล้ว";
    } else if (seconds > 59) {
      return "เมื่อ " + parseInt(minute) + " นาทีที่แล้ว";
    } else {
      return "เมื่อสักครู่นี้";
    }
  };

  const twoDigit = (n) => {
    let number = parseInt(n);
    if (number <= 9) {
      return "0" + number;
    } else {
      return "" + number;
    }
  };

  const ConvertDate = (dateString, type = "datetime") => {
    if (dateString) {
      let date = new Date(dateString);
      if (type === "datetime") {
        return (
          date.getFullYear() +
          "-" +
          twoDigit(date.getMonth() + 1) +
          "-" +
          twoDigit(date.getDate()) +
          " " +
          twoDigit(date.getHours()) +
          ":" +
          twoDigit(date.getMinutes()) +
          ":" +
          twoDigit(date.getSeconds())
        );
      }
      if (type === "date") {
        return (
          date.getFullYear() +
          "-" +
          twoDigit(date.getMonth() + 1) +
          "-" +
          twoDigit(date.getDate())
        );
      }
      if (type === "time") {
        return (
          twoDigit(date.getHours()) +
          ":" +
          twoDigit(date.getMinutes()) +
          ":" +
          twoDigit(date.getSeconds())
        );
      }
    } else {
      return "";
    }
  };

  React.useEffect(() => {
    const controller = new AbortController();
    onGetNotification();
    return () => {
      controller.abort();
    };
  }, []);

  React.useEffect(() => {
    const controller = new AbortController();

    // console.log("timerRefresh : ", timerRefresh);

    if (timerRefresh == 60) {
      setTimerRefresh(0);

      onGetNotification();
    }

    return () => {
      controller.abort();
    };
  }, [timerRefresh]);

  React.useEffect(() => {
    const RefreshInternal = setInterval(() => {
      setTimerRefresh((t) => t + 1);
    }, 1000);
    return () => {
      clearInterval(RefreshInternal);
    };
  }, []);

  return (
    <div className="navbar-container">
      <div className="title" onClick={() => onNavigatePage("/dashboard")}>Smooth Ride Dashboard</div>
      <div style={{ flexGrow: 1 }}></div>
      <div className="user-name"> {AuthContext.user.mobileno} </div>
      <button className="btn btn-light" onClick={() => setStateNotiList(true)}>
        {onGetNotiCount() > 0 ? (
          <div className="noti-pop"> {onGetNotiCount()} </div>
        ) : null}

        {notiAlert ? <div className="noti-pop"> ! </div> : null}

        <img className="btn-icon" src={notiIcon} />
      </button>

      <button className="btn btn-light" onClick={() => setStateMenu(true)}>
        <img className="btn-icon" src={menuIcon} />
      </button>

      <div className={"side-nav " + (stateMenu ? "active" : null)}>
        <div className="side-nav-title-container">
          <div className="side-nav-title">เมนู</div>
          <button className="btn btn-light" onClick={() => setStateMenu(false)}>
            <img className="btn-icon" src={closeIcon} />
          </button>
        </div>
        <div className="side-nav-menu-container">
          {menuList.map((menu, index) => {
            return (
              <div
                className="side-nav-menu-button"
                key={index}
                onClick={() => onNavigatePage(menu.path)}
              >
                {menu.displayName}
              </div>
            );
          })}
          <div style={{ flexGrow: 1 }}></div>

          <div
            className="side-nav-menu-button"
            style={{
              justifyContent: "center",
              fontWeight: "bolder",
              border: "1px solid #666",
            }}
            onClick={() => onLogOut()}
          >
            Logout
          </div>
        </div>
      </div>

      <div className={"side-nav " + (stateNotiList ? "active" : null)}>
        <div className="side-nav-title-container">
          <div className="side-nav-title">แจ้งเตือนรถล้มวันนี้</div>
          <button
            className="btn btn-light"
            onClick={() => {
              setStateNotiList(false);
              setAlert(false);
              //   onReadAllNoti();
            }}
          >
            <img className="btn-icon" src={closeIcon} />
          </button>
        </div>

        <div className="side-nav-menu-container">
          {notiList.map((noti, index) => {
            return (
              <div
                className="noti-list"
                key={index}
                onClick={() => onReadByIndex(index)}
              >
                <div className="noti-list-time">
                  {noti.read === false ? (
                    <span className="remark-no-read"></span>
                  ) : null}
                  <span>{getTimeWording(noti.time)} </span>
                </div>
                <div className="noti-list-row">
                  <div className="toppic">หัวข้อ :</div>
                  <div className="value danger">อุบัติเหตุยานพาหนะพลิกคว่ำ</div>
                </div>
                <div className="noti-list-row">
                  <div className="toppic">หมายเลขผู้ใช้งาน :</div>
                  <div className="value">{noti.userid}</div>
                </div>
                <div className="noti-list-row">
                  <div className="toppic">หมายเลขโทรศัพท์ :</div>
                  <div className="value">{(noti.mobileno) ? noti.mobileno : ""}</div>
                </div>
                <div className="noti-list-row">
                  <div className="toppic">เวลา :</div>
                  <div className="value">{ConvertDate(noti.time)}</div>
                </div>
                <div className="noti-list-row">
                  <div className="toppic">หมายเลขถนน :</div>
                  <div className="value">{noti.road_no}</div>
                </div>
                <div className="noti-list-row">
                  <div className="toppic">กิโลเมตร :</div>
                  <div className="value">{ noti.km ? parseFloat(noti.km).toFixed(2) : ""}</div>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
}

export default Navbar;

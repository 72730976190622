
import './App.css';
import React from 'react';
import {
  Navigate,
  BrowserRouter as Router,
  useRoutes,
} from "react-router-dom";


// import { setCookie } from './component/service/CookieManagement';
// import { HttpRequest } from './component/service/HttpRequest';
import { useAuth } from './component/service/Authentication';

import LoginPage from './page/login';
import DashboardPage from './page/dashboard';
import RealtimePage from './page/realtime';
import ManageUserPage from './page/manage-user';
// import ManageDevicePage from './page/manage-device';
import ManageRoadPage from './page/manage-road';

import NavigationBar from './component/navbar';


const MainRoute = () => {
  const auth = useAuth();

  let routeList = [
    { path: "/dashboard", name: "dashboard", displayName: "หน้าหลัก", element: <DashboardPage />, enable: true },
    { path: "/realtime", name: "realtime", displayName: "แผนที่แสดงเส้นทาง", element: <RealtimePage />, enable: true },
    { path: "/manage-user", name: "manage_user", displayName: "จัดการผู้ใช้งาน", element: <ManageUserPage />, enable: false },
    // { path: "/manage-device", name: "manage_device", displayName: "จัดการอุปกรณ์", element: <ManageDevicePage /> },
    { path: "/manage-road", name: "manage_road", displayName: "จัดการถนน", element: <ManageRoadPage />, enable: true },
  ]

  let menuList = [];

  let PrivateRoutes = [
    { path: "/", element: <Navigate replace to="/dashboard" /> },
    { path: "/login", element: <Navigate replace to="/dashboard" /> },
  ]

  let PublicRoutes = [
    { path: "/", element: <Navigate replace to="/login" /> },
    { path: "/login", element: <LoginPage /> }
  ]

  for (let i = 0; i < routeList.length; i++) {
    const route = routeList[i];
    if (route.enable === true) {
      menuList.push({path: route.path, name: route.name, displayName: route.displayName});
    }
    PrivateRoutes.push({ path: route.path, element: route.element });
    PublicRoutes.push({ path: route.path, element: <Navigate replace to="/login" /> });
  }

  const routeUse = auth.user ? PrivateRoutes : PublicRoutes;
  const IntitailRoute = useRoutes(routeUse);

  return (
    <div className="main-contrainer">
      {auth.user ? <NavigationBar menuList={menuList} /> : null}
      {IntitailRoute}
    </div>
  );
}

function App() {

  return (
    <Router>
      <MainRoute />
    </Router>
  );
}

export default App;
import './styles.css';
import React from 'react';
import { useNavigate } from "react-router-dom";

function SummaryBox(props) {

    const navigate = useNavigate();
    const onNavigatePage = (path = props.path) => {
        if (path && path !== '') { navigate(path); }
    }
    
    return (
        <div className={"summary-box-container " + (props.path !== "" ? "shortcut" : null)} onClick={() => onNavigatePage()}>
            <div className='summary-box-header'>{props.title}</div>
            <div className='summary-box-content'>
                {props.children}
            </div>
        </div>
    )
}

export default SummaryBox;
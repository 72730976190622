import './styles.css';
import React from 'react';

import { HttpRequest } from '../../component/service/HttpRequest';
import { setCookie } from '../../component/service/CookieManagement';
import { useAuth } from '../../component/service/Authentication';

import Spinner from "../../component/spinner";

// import logo from '../../assets/logo.png';

function LoginPage() {

    const auth = useAuth();

    const [formControl, setFormControl] = React.useState({
        username: "",
        password: ""
    });

    const [stateLogin, setStateLogin] = React.useState("loading"); // ready, false, loading, success

    const handleSubmit = (event) => {
        event.preventDefault();

        setStateLogin("loading");

        // login({
        //     token: "DUMMY-TOKEN",
        //     user:  "DUMMY-USER"
        // })

        let dataReq = {
            mobileno: formControl.username,
            pin: formControl.password
        }

        HttpRequest("/admin/login", dataReq).then(data => {
            console.log(data);

            if (data.result === "OK") {

                let authenData = {
                    token: data.session.sessiontoken,
                    user: data.user
                }
                // 0802389000
                console.log("authenData : ", authenData);

                setCookie("smooth_ride_backend_login_user", authenData.user);
                setCookie("smooth_ride_backend_login_token", authenData.token);

                setStateLogin("success");
                setTimeout( auth.login( authenData ), 3000);

            } else {
                
                setStateLogin("false");

            }

        });
    };

    React.useEffect(() => {
        const controller = new AbortController();
    
        HttpRequest("/admin/checksession", {}).then(data => {
          console.log("HttpRequest :", data);
          if (data.result === "OK") {
            if (data.session.logout === false) {
              let authenData = {
                token: data.session.sessiontoken,
                user: data.user
              }
    
              setCookie("smooth_ride_backend_login_user", authenData.user);
              setCookie("smooth_ride_backend_login_token", authenData.token);
    
              setTimeout(auth.login(authenData), 3000);
            }else{
                setStateLogin("ready");
            }
          }else{
            setStateLogin("false");
          }
    
        });
    
        return () => { controller.abort() };
      }, [auth]);

    return <div className='main-content'>

        <form className='form-login-content' onSubmit={handleSubmit}>
            {/* <div className='form-contrainer-logo'>
                <img src={logo} className='img_logo' alt="Logo" />
            </div> */}

            <p className='form-toppic'>Smooth Ride Backend</p>

            {
                stateLogin === "ready" ?
                    <>

                        <div className='form-row'>
                            <label className="form-label">Username</label>
                            <input type="text" placeholder="Username" className='form-control'
                                value={formControl.username}
                                onChange={(event) => setFormControl({ ...formControl, username: event.target.value })} />
                        </div>

                        <div className='form-row'>
                            <label className="form-label">Password</label>
                            <input type="password" placeholder="Password" className='form-control'
                                value={formControl.password}
                                onChange={(event) => setFormControl({ ...formControl, password: event.target.value })} />
                        </div>

                        <div className='form-row'>
                            <button className='bt-login form-control' type='submit'>Login</button>
                        </div>

                        <div className='form-row'>
                            <span className='q'>Don't have an Account?</span>
                            <a className='h'>Contact Administrator</a>
                        </div>
                    </> : (null)
            }

            {
                stateLogin === "loading" ?
                    <>

                        <div className='form-row'>
                            <Spinner></Spinner>
                            <label style={{marginLeft: 15}} className="form-label">Authenticating..</label>
                        </div>

                    </> : (null)
            }


            {
                stateLogin === "false" ?
                    <>

                        <div className='form-row'>
                            <label className="form-label">Authentication Fail</label>
                        </div>

                        <div className='form-row'>
                            <span className='q'>Please login again.</span>
                            <a className='h' onClick={() => setStateLogin("ready")}>OK</a>
                        </div>

                    </> : (null)
            }

            {
                stateLogin === "success" ?
                    <>

                        <div className='form-row'>
                            <label className="form-label">Authentication Success</label>
                        </div>

                        <div className='form-row'>
                            <Spinner></Spinner>
                            <span style={{marginLeft: 15}} className='q'>Redirecting..</span>
                        </div>

                    </> : (null)
            }

        </form>

    </div>;
}

export default LoginPage;